
    import { loadStoryBlokData, storyMixin } from '../helpers/storyblok-loader';
    
    export default {
        mixins: [storyMixin],
        
        data() {
            return {
                langcode: null,
            };
        },
        
        async mounted() {
        },
        
        methods: {},
        
        asyncData(context) {
            return loadStoryBlokData(context);
        },
    };
